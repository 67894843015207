import React, { useState } from "react";
import {graphql, useStaticQuery} from "gatsby";

import TitleWithLogo from "../titleWithLogo"
import { ModalYoutube } from "../modals"
import BackgroundImg from "../backgroundImg";
import Swiper from "react-id-swiper";

import PlaySvg from "../../images/svg/play.svg";

import contents from "./onAirSlider.yml";

import 'swiper/css/swiper.css';
import './on-air-slider.scss';
import {compareDisplayOrderDesc} from "../../helpers";

const OnAirSlider = () => {

  const data = useStaticQuery(graphql`
    query {
      cmsApi {
        getOnairListing {
          edges {
            node {
              id
              displayOrder
              onairTitle
              onairYoutubeVideoId
              onairImage {
                fullpath
              }
            }
          }
        }
      }
    }
  `);
  const slides = data.cmsApi.getOnairListing.edges;
  slides.sort(compareDisplayOrderDesc);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalYoutubeVideoId, setModalYoutubeVideoId] = useState(null);
  const openModal = (videoId) => {
    setModalYoutubeVideoId(videoId);
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
    setModalYoutubeVideoId(null);
  };

  const swiperParams = {
    spaceBetween: 0,
    a11y: {
      prevSlideMessage: 'Slide precedente',
      nextSlideMessage: 'Slide successiva',
      firstSlideMessage: 'Questa è la prima slide',
      lastSlideMessage: 'Questa è l’ultima slide'
    },
    speed: 1000,
    watchOverflow: true,
    watchSlidesVisibility: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    }
  };

  return (
    <section className="on-air__wrapper">
      <div className="title__wrapper">
        <TitleWithLogo
          title={contents.title}
        />
      </div>

      <Swiper containerClass="swiper-container swiper-container--on-air" {...swiperParams}>

      { slides && slides.length && slides.map(({node}, i)=>{

        const {
          onairTitle,
          onairYoutubeVideoId,
          onairImage
        } = node;

        return(
          <div
            key={i}
            className="swiper-slide"
          >
            <button
              className="on-air__video"
              onClick={ () => { openModal(onairYoutubeVideoId); } }
            >
              <BackgroundImg
                className="inherit-height"
                alt={onairTitle}
                src={`${process.env.GATSBY_CMS_ASSETS_BASEURL}${onairImage.fullpath}`}
              />
              <PlaySvg
                className="play-button"
              />
            </button>

            <h3 className="on-air__title">
              {onairTitle}
            </h3>

          </div>
        );

      })}
      </Swiper>

      <ModalYoutube
        modalIsOpen={modalIsOpen}
        onClick={closeModal}
        videoID={modalYoutubeVideoId}
        label={contents.seeVideo}
      />

    </section>
  )
};

export default OnAirSlider;
