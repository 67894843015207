import React from 'react';
import FullpageSection from "../fullpageSection"
import BackgroundMoreThanBeer from "../backgroundImages/backgroundMoreThanBeer"

import { resolveLinkToRoute } from '../../helpers';

import contents from "./fullpageSectionCeresWorld.yml";

const FullpageSectionCeresWorld = () => {

  const {
    titleTag,
    title,
    subtitle,
    text,
    link
  } = contents;

  return (
    <FullpageSection
      whichTitle={titleTag}
      copyTitle={title}
      haveSubtitle={!!(subtitle && subtitle !== '')}
      copySubtitle={subtitle}
      copyText={text}
      haveCTA={!!(link && link.to && link.to !== '')}
      buttonLinkTo={link && link.to && resolveLinkToRoute(link.to)}
      buttonLinkTarget={link && link.target}
      buttonLinkLabel={link && link.label}
      buttonLinkTrackingCategory={link && link.trackingCategory}
    >
      <BackgroundMoreThanBeer />
    </FullpageSection>
  )
};

export default FullpageSectionCeresWorld;
