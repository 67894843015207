import React from "react"
import SEO from "../components/seo"
import OnairSlider from "../components/onAirSlider";
import OnTheRoadSlider from "../components/onTheRoadSlider";
import OnTheWeb from "../components/onTheWeb";
import FullpageSectionCeresWorld from "../components/fullpageSectionCeresWorld";

const CeresWorldPage = () => {



  return (
    <main>
      <SEO
        title="Il mondo Ceres"
        description="Vuoi scoprire quanto ci piace prendere (e prenderci) in giro sui social?"
      />


      <FullpageSectionCeresWorld/>
      <OnTheWeb/>
      <OnTheRoadSlider/>
      <OnairSlider/>

    </main>
  );

};

export default CeresWorldPage;
