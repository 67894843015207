import React, {useState} from 'react';
import {graphql, useStaticQuery} from "gatsby";

import TitleWithLogo from "../titleWithLogo"
import { ModalYoutube } from "../modals"
import BackgroundImg from "../backgroundImg";
import ButtonLink from "../buttonLink";
import Swiper from "react-id-swiper";

import contents from "./onTheRoadSlider.yml";

import 'swiper/css/swiper.css';
import "./onTheRoadSlider.scss";
import {compareDisplayOrderDesc} from "../../helpers";

const OnTheRoadSlider = () => {

  const data = useStaticQuery(graphql`
    query {
      cmsApi {
        getOntheroadListing {
          edges {
            node {
              displayOrder
              ontheroadText
              ontheroadImage {
                fullpath
              }
              ontheroadLink {
                text
                path
              }
              ontheroadYoutubeVideoId
            }
          }
        }
      }
    }
  `);
  const slides = data.cmsApi.getOntheroadListing.edges;
  slides.sort(compareDisplayOrderDesc);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalYoutubeVideoId, setModalYoutubeVideoId] = useState(null);
  const openModal = (videoId) => {
    setModalYoutubeVideoId(videoId);
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
    setModalYoutubeVideoId(null);
  };

  const swiperParams = {
    spaceBetween: 0,
    a11y: {
      prevSlideMessage: 'Slide precedente',
      nextSlideMessage: 'Slide successiva',
      firstSlideMessage: 'Questa è la prima slide',
      lastSlideMessage: 'Questa è l’ultima slide'
    },
    speed: 1000,
    watchOverflow: true,
    watchSlidesVisibility: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    }
  };

  return (
    <section className="on-the-road__wrapper">
      <div className="title__wrapper">
        <TitleWithLogo
          title={contents.title}
        />
      </div>

      <Swiper containerClass="swiper-container swiper-container--on-the-road" {...swiperParams}>

      { slides && slides.length && slides.map(({node}, i)=>{

        const {
          ontheroadText,
          ontheroadImage,
          ontheroadLink,
          ontheroadYoutubeVideoId
        } = node;

        return(
          <div
            key={i}
            className="swiper-slide"
          >
            <BackgroundImg
              className="background-wrapper"
              alt={ontheroadText}
              src={`${process.env.GATSBY_CMS_ASSETS_BASEURL}${ontheroadImage.fullpath}`}
            />
            <div className="content__wrapper">
              <h3 className="title">
                {ontheroadText}
              </h3>
              <div className="cta-wrapper">
                { ontheroadYoutubeVideoId &&
                  <ButtonLink
                    label={contents.seeVideo}
                    onClick={ () => { openModal(ontheroadYoutubeVideoId); } }
                  />
                }

                { ontheroadLink &&
                  <ButtonLink
                    label={ontheroadLink.text}
                    target="_blank"
                    to={ontheroadLink.path}
                  />
                }
              </div>
            </div>
          </div>
        );

      })}

      </Swiper>


      <ModalYoutube
        modalIsOpen={modalIsOpen}
        onClick={closeModal}
        videoID={modalYoutubeVideoId}
        label={contents.seeVideo}
      />

    </section>
  )
};

export default OnTheRoadSlider;
